@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

html {
  font-size: 16px;
  font-family: Rubik, Arial, Helvetica, sans-serif;
}

div {
  box-sizing: border-box;
}

p {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

/*  change font family to all elements */
* {
  font-family: Rubik, Arial, Helvetica, sans-serif;
}

input[type='checkbox'] {
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
}

/*

*/
::-webkit-scrollbar {
  width: 6px;
  max-height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  max-width: 6px;
  max-height: 6px;
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  max-width: 6px;
  max-height: 6px;
  background: #494949;
  border-radius: 3px;
}

@media only screen and (max-width: 768px) {
  html {
    /* font-size: 14px; */
  }
}
